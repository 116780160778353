/* You can add global styles to this file, and also import other style files */
@use 'sass:math';
@import "./assets/scss/form";
@import "./assets/scss/button";
@import "./assets/scss/mixins";
@import "./assets/scss/colors";

html,
body {
    height: 100%;
}

.btn {
    box-shadow: none !important;
}

.asset-card {
    @include aiHubContainer();
    position: relative;
    padding: 3rem;
    background: white;
    flex: 0 0 calc(50% - #{math.div(rem-calc(29px), 2)});
    margin-bottom: rem-calc(29px);
    display: flex;
    flex-direction: column;

    .asset-card--header {
        display: flex;
        justify-content: space-between;
    }

    .asset-card--body {
        flex: 1;
    }
}

app-asset-publish-dashboard {
    .deloitteai__banner {
        height: 13rem !important;
    }

    .deloitteai__banner--inner {
        .content__logo {
            margin-bottom: 0rem !important;
        }
    }

    .container {
        margin-left: auto;
        margin-right: auto;
    }

    deloitteai-page-banner {
        margin-bottom: 3.3rem !important;
    }

    .entitlement_count {
        background-color: $deloitte-green;
        border-radius: 50%;
        height: rem-calc(28px);
        width: rem-calc(28px);
        padding-top: rem-calc(1.5);
        font-size: rem-calc(15px);
        text-align: center;
        vertical-align: middle;
        color: #fff;
        font-weight: normal;
    }
}

app-asset-publish-asset-detail {
    .container {
        margin-left: auto;
        margin-right: auto;
    }

    .detail {
        font-size: 14px;
        font-weight: 700;
    }

    .label {
        font-size: 14px;
    }
}

app-asset-publish-success {
    min-height: 84vh;

    .container {
        margin-left: auto;
        margin-right: auto;
    }
}

.detail-header {
    font-family: OpenSansBold;
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-bottom: 1.875rem;
    display: flex;
    min-width: 0;
}

.card-filter-btn {
    padding: 0.1rem 1rem !important;
    font-size: 0.875rem !important;
    color: #000000 !important;
    border: 1px solid #e8e8e8 !important;
    height: 2rem;
}

.badge {
    border-radius: unset;
    text-transform: uppercase;
    font-size: rem-calc(12px);
    padding: 0.2rem rem-calc(30px);
    background: inherit;
}

.badge.danger {
    border: 1px solid $red;
    color: $red;
}

.badge.success {
    border: 1px solid $deloitte-green;
    color: $deloitte-green;
}

// Dropdowns - Mat select

mat-select,
.mat-select {
    border: 1px solid $border-color;
    border-radius: 2px;
    padding: 13px 18px;
    width: 100%;
    display: block;
    box-shadow: 0 5px 5px 0 #e4e4e6;
    line-height: 24px;
}

mat-option,
.mat-option {
    margin-bottom: 8px;
    border-left: 4px solid transparent;
    font-family: OpenSansRegular !important;
    padding-left: 1rem !important;
    line-height: 2.1rem !important;
    font-size: 0.9rem !important;
    height: 2.1rem !important;
    &.mat-active {
        background: transparent !important;
        color: $deloitte-green;
        font-weight: bold;
        border-left: 4px solid $deloitte-green;
    }
}


.mat-select-value-text {
    font-weight: 500;
}

.timeline {
    position: relative;

    & > li::before {
        content: "";
        position: absolute;
        width: 1px;
        background-color: $blue;
        top: 0;
        bottom: 0;
        left: -30px;
    }

    & > li::after {
        text-align: center;
        content: counter(item);
        position: absolute;
        width: 25px;
        height: 25px;
        background-color: $blue;
        color: #fff !important;
        border-radius: 50%;
        top: 0;
        left: -43px;
    }

    & > li {
        counter-increment: item;
        padding: 1px 10px;
        position: relative;
        list-style: none;
    }

    & > li:nth-last-child(1)::before {
        width: 0px;
    }
}
