@import "./app-icons";

@mixin iconButton($size: 2, $iconFontSize: 1.5) {
    width: #{$size}rem;
    height: #{$size}rem;
    border-radius: #{$size / 2}rem;

    -webkit-box-shadow: 0 -3px 13px 4px rgba(0, 163, 224, 0.09), 0 13px 9px -6px rgba(0, 163, 224, 0.38);
    -moz-box-shadow: 0 -3px 13px 4px rgba(0, 163, 224, 0.09), 0 13px 9px -6px rgba(0, 163, 224, 0.38);
    box-shadow: 0 -3px 13px 4px rgba(0, 163, 224, 0.09), 0 13px 9px -6px rgba(0, 163, 224, 0.38);

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        box-shadow: -2px -5px 4px 0 rgba(0, 163, 224, 0.09), 3px 8px 4px -6px rgba(0, 163, 224, 0.38);
    }

    i.icon {
        font-size: #{$iconFontSize}rem;
    }
}

/**
 * box shadow
 */
@mixin boxShadow($color: #e4e4e6) {
    box-shadow: 0 5px 5px 0px $color;
}

@mixin aiHubContainer {
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    padding: 13px 18px;
    width: 100%;
    display: block;
    @include boxShadow;
}

/**
 * input field form
 */
@mixin formControl {
    @include aiHubContainer();

    &:focus {
        outline: none;
        border: 1px solid #86bc25;
        @include boxShadow(rgba(134, 188, 37, 0.32));
    }
}

/**
 * service owner button
 */
@mixin serviceOwnerButton($icon, $iconAlign: $iconAlignLeft, $color, $size: auto) {

    display: inline-flex;
    padding: .5rem 1rem;
    border: 1px solid;
    align-items: center;

    @if $icon {

        i.so-icon {
            @include serviceOwnerIcon($icon, $color, $size);
            height: 1.1rem;
            width: 1.2rem;
            margin: 0 .5rem 0 0;
        }
    }

    @if $iconAlign == $iconAlignRight {
        flex-direction: row-reverse;

        i.so-icon {
            margin: 0 0 0 .5rem;
        }
    }
}

@mixin gradient($direction: left) {

    @if $direction == left {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+60,999999+100&1+0,0.75+60,0+100 */
        background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 60%, rgba(153,153,153,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 60%,rgba(153,153,153,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0.75) 60%,rgba(153,153,153,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    } @else {
        background: -moz-linear-gradient(left, rgba(153,153,153,0) 0%, rgba(255,255,255,0.75) 40%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(153,153,153,0) 0%,rgba(255,255,255,0.75) 40%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(153,153,153,0) 0%,rgba(255,255,255,0.75) 40%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}
