@use 'sass:math';
@import "./mixins";
@import "./rem-calc";

@function centerInnerBullet($outerWidth, $innerWidth) {
    $centerOuter: math.div($outerWidth, 2);
    $centerInner: math.div($innerWidth, 2);
    @return $centerOuter - $centerInner;
}

.ai-hub-form {
    .aihub-combobox-select {
        position: relative;
        border: 20px solid;
    }

    .ai-hub-form--control {
        margin-bottom: rem-calc(30px);

        label {
            display: flex;
            flex-direction: column;
        }

        label.required small:after {
          content: "*"
        }

        .label.small {
            font-size: 0.8125rem; // 13px
        }

        .footnote {
            color: #97999b;
            font-style: italic;
            margin: 1rem 0;
        }
    }

    .ai-hub-form--control.radio {
        label {
            flex-direction: row;
            align-items: baseline;
        }
    }

    input[type="number"],
    input[type="text"] {
        margin-top: 0.5rem;
        border: 1px solid #e9e9e9;
        border-radius: 2px;
        padding: 13px 18px;
        box-shadow: 0 5px 5px 0px #e4e4e6;
        font-size: 1rem;

        &:focus {
            outline: none;
            border: 1px solid #86bc25;
            box-shadow: 0 5px 5px 0 rgba(134, 188, 37, 0.32);
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }

    /**
     * radio button
     */
    input[type="radio"] {
        $bulletRadius: 1rem;
        $innerBulletRadius: rem-calc(10px);
        display: none;

        & + span {
            position: relative;
            padding: 0 0 0 2rem;

            &:before,
            &:after {
                content: "";
                left: 0;
                height: $bulletRadius;
                width: $bulletRadius;
                border: 1px solid #86bc25;
                border-radius: math.div($bulletRadius, 2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &:after {
                width: $innerBulletRadius;
                height: $innerBulletRadius;
                border-color: transparent;
                left: centerInnerBullet(1rem, $innerBulletRadius);
                border-radius: math.div($bulletRadius, 2);
            }
        }

        &:checked + span {
            &:after {
                background-color: #86bc25;
                border-color: white;
            }
        }
    }

    /**
     * better way, the old way just included since i dont want refactor
     * everything now
     */
    input[type="radio"] + span.input-radio {

        $bulletRadius: 1rem;
        $innerBulletRadius: rem-calc(10px);

        position: relative;
        padding: 2px;
        margin: 0 1rem 0 0;
        flex: 0 0 $bulletRadius;
        height: $bulletRadius;

        &:before,
        &:after {
            content: "";
            left: 0;
            height: $bulletRadius;
            width: $bulletRadius;
            border: 1px solid #86bc25;
            border-radius: math.div($bulletRadius, 2);
            position: absolute;
        }

        &:after {
            $center: centerInnerBullet(1rem, $innerBulletRadius);
            width: $innerBulletRadius;
            height: $innerBulletRadius;
            border-color: transparent;
            border-radius: math.div($bulletRadius, 2);
            transform: translate($center, $center);
            top: 0;
        }
    }

    input[type="checkbox"] {
        & + span:after {
            content: none;
        }

        & + span:before {
            border-radius: rem-calc(2px);
        }

        &:checked + span {
            &:before {
                background-color: #86bc25;
            }

            &:after {
                content: "";
                height: rem-calc(12px);
                width: rem-calc(6px);
                transform: translateY(-60%) rotateZ(45deg);
                left: rem-calc(5px);
                border-style: solid;
                border-color: transparent white white transparent;
                border-width: 0 2px 2px 0;
                border-radius: 0;
            }
        }
    }

    .ai-hub-form--radio-group {
        display: flex;
        flex-direction: row;

        > label {
            flex: 0 0 10rem;
        }
    }

    .ai-hub-form--control {
        margin-bottom: 2.5rem;
    }

    .ai-hub-form--control .error {
        background: inherit;
        color: red;
    }
}
